// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family:  'Poppins', sans-serif;

  /** primary **/
  --ion-color-primary: #6032e7;
	--ion-color-primary-rgb: 68,23,230;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #3c14ca;
	--ion-color-primary-tint: #572ee9;

  /** secondary **/
  --ion-color-secondary: #17006A;
	--ion-color-secondary-rgb: 23,0,106;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #14005d;
	--ion-color-secondary-tint: #2e1a79;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82,96,255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #FED030;
	--ion-color-warning-rgb: 254,208,48;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0b72a;
	--ion-color-warning-tint: #fed545;

  /** danger **/
  --ion-color-danger: #E50000;
	--ion-color-danger-rgb: 229,0,0;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #ca0000;
	--ion-color-danger-tint: #e81a1a;

  /** dark **/
  --ion-color-dark: #2E3034;;
	--ion-color-dark-rgb: 46,48,52;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255,255,255;
	--ion-color-dark-shade: #282a2e;
	--ion-color-dark-tint: #434548;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

  //--ion-tab-bar-background: linear-gradient(180deg, rgba(97, 61, 227, 1) 0%, rgba(95, 59, 226, 0.94) 49%, rgba(60, 16, 213, 1) 100%);
  --ion-tab-bar-background: var(--ion-color-primary); 
  //--ion-toolbar-background: rgba(97, 61, 227, 1);
  // --ion-background-color: #4417E6;
  // --ion-background-color-rgb: 68,23,230;
  --swiper-pagination-bullet-width: 9px;
  --swiper-pagination-bullet-height: 9px;
  --swiper-navigation-size: 30px !important;
  --swiper-navigation-color: #fff !important;
}

/*
  * Material Design Dark Theme
  * -------------------------------------------
  */

  // .md body {
  //   --ion-background-color: #4417e6;
  //   --ion-background-color-rgb: 68,23,230;

  //   --ion-text-color: #2E3034;
	//   --ion-text-color-rgb: 46,48,52;

  //   --ion-color-step-50: #4116db;
  //   --ion-color-step-100: #3d15cf;
  //   --ion-color-step-150: #3a14c4;
  //   --ion-color-step-200: #3612b8;
  //   --ion-color-step-250: #3311ad;
  //   --ion-color-step-300: #3010a1;
  //   --ion-color-step-350: #2c0f96;
  //   --ion-color-step-400: #290e8a;
  //   --ion-color-step-450: #250d7f;
  //   --ion-color-step-500: #220c73;
  //   --ion-color-step-550: #1f0a67;
  //   --ion-color-step-600: #1b095c;
  //   --ion-color-step-650: #180851;
  //   --ion-color-step-700: #140745;
  //   --ion-color-step-750: #11063a;
  //   --ion-color-step-800: #0e052e;
  //   --ion-color-step-850: #0a0323;
  //   --ion-color-step-900: #070217;
  //   --ion-color-step-950: #03010c;
  
  //   --ion-item-background: #4417e6;
  
  //   --ion-toolbar-background: #4417e6; 
  
  //   --ion-tab-bar-background: #4417e6;
  
  //   --ion-card-background: #4417e6;
  // }

  ion-modal{
    --height: 100%;
  }