@import '_rem.scss';
@mixin uno-font-faces($font-label, $font-name, $font-weight, $italic: false) {

    #{".ff-"}#{$font-label} {
        font-family: $font-name !important;
        font-weight: $font-weight !important;
        @if($italic){
            font-style: italic;
        }
    }

    %#{$font-label} {
        font-family: $font-name, sans-serif !important;
        font-weight: $font-weight !important;
        @if($italic){
            font-style: italic;
        }
    }
}
@mixin uno-font-sizes($min: 1, $max: 100) {
    #{".fs"} {
        @for $i from $min through $max {
            &-#{$i} {
               font-size: rem($i) !important;
            }
        }
    }
}
@include uno-font-faces('pl', 'Poppins', 300);
@include uno-font-faces('pr', 'Poppins', 400);
@include uno-font-faces('pri', 'Poppins', 400, true);
@include uno-font-faces('pm', 'Poppins', 500);
@include uno-font-faces('pb', 'Poppins', 700);
@include uno-font-faces('pbi', 'Poppins', 700, true);
@include uno-font-faces('peb', 'Poppins', 800);
@include uno-font-sizes();