/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

$enable-negative-margins: true;
$primary: #6032e7;
$secondary: #17006A;
$warning: #FED030;
$danger: #E50000;
$dark: #2E3034;
$aspect-ratios: (
  "1x1": 100%,
  "1x2": calc(2 / 1 * 100%),
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
);
// Configuration
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
//@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
//@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
//@import "../node_modules/bootstrap/scss/nav";
//@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
//@import "../node_modules/bootstrap/scss/breadcrumb";
//@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
//@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
//@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
//@import "../node_modules/bootstrap/scss/carousel";
//@import "../node_modules/bootstrap/scss/spinners";
//@import "../node_modules/bootstrap/scss/offcanvas";
//@import "../node_modules/bootstrap/scss/placeholders";

// Helpers
@import "../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../node_modules/bootstrap/scss/utilities/api";

@import "~leaflet/dist/leaflet.css";
@import "~swiper/swiper-bundle.min.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;0,800;1,400;1,700&display=swap");

//rfs
html {
    @media (max-width: 411px) {
      font-size: 100%;
    }
    @media (max-width: 360px) {
      font-size: 85%;
    }
    @media (max-width: 330px) {
      font-size: 70%;
    }
    @media (max-width: 326px) {
      font-size: 62.5%;
    }
    @media (max-width: 325px) {
      font-size: 59%;
    }
    @media (max-width: 320px) {
      font-size: 57%;
    }
    @media (max-width: 300px) {
      font-size: 56.5%;
    }
    @media (max-width: 290px) {
      font-size: 55.5%;
    }
    @media (max-width: 289px) {
      font-size: 52.5%;
    }
  }
  
  html.ios {
    @media (max-width: 359px) and (min-width: 320px) {
      font-size: 59%;
    }
  }
  
  //utilities css
  .min-h-100 {
    min-height: 100%;
  }

.swiper-pagination { 
    .swiper-pagination-bullet { border: 2px solid #818181; background: #fff; opacity: 1;
        &.swiper-pagination-bullet-active { border-color: #fff; background: var(--ion-color-warning); }
    }
}
.leaflet-tile-pane {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
.bg { background: linear-gradient(180deg, rgba(97, 61, 227, 1) 0%, rgba(95, 59, 226, 0.94) 49%, rgba(60, 16, 213, 1) 100%);
  &-F0EBFF{
    background: #F0EBFF;
  }
}
.fc {
  &-4417E6 {
    color: #4417E6 !important;
  }
}
.btn {
  &-primary {
    --color: var(--ion-color-step-950);
    --background: var(--ion-color-primary);
  }
  &-secondary {
    --color: var(--ion-color-secondary-contrast);
    --background: var(--ion-color-secondary);
    &:hover{
      --background: linear-gradient(180deg, rgba(235,169,18,1) 0%, rgba(254,208,48,1) 100%);
    }
  }
  &-danger {
    --color: var(--ion-color-danger-contrast);
    --background: var(--ion-color-danger);
  }
  &-warning { opacity: 1 !important;
    --color: var(--ion-color-secondary);
    --background: linear-gradient(180deg, rgba(235,169,18,1) 0%, rgba(254,208,48,1) 100%);
    &:hover{
      --background: var(--ion-color-danger); color: $white !important;
    }
  }
}
.object-fit {
    &-cover {
        object-fit: cover;
    }

    &-contain {
        object-fit: contain;
    }
}

.object-position{
    &-left { object-position: left; }
    &-right { object-position: right; }
    &-center { object-position: center; }
}

.letter-spacing {
  &-1 { letter-spacing: 1px; }
  &-2 { letter-spacing: 2px; }
}

.z-index{
  &-0 { z-index: 0; }
  &-1 { z-index: 1; }
}

.modal-search{
  ion-header{
    ion-toolbar { --background: #E4E5FE;
      ion-searchbar { --background: #fff;
        ion-icon { color: var(--ion-color-primary) !important; }
      }
    }
  }
  ion-content { --background: #E4E5FE; }
}